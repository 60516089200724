import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Card, Button, Spinner } from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { getPlants } from "../plant-helper";

const PlantOverview = () => {
        const [plants, setPlants] = useState();
        const fetchPlants = async () => {
            if (!plants) {
                const p = await getPlants();
                setPlants(p);
            }
        }

        useEffect(() => {
            fetchPlants();
        });

        return (
            <Container className="py-5">
                <h1 className="mb-5">Mein Pflanzen-Lexikon</h1>

                {!plants &&
                <div className="text-center pt-5">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
                }


                <Row>
                    {plants && plants.map((plant, index) => (
                        <Col sm={4} key={index} className="py-3">
                            <Card>
                                <Card.Img variant="top"
                                          src={plant.img}/>
                                <Card.Body>
                                    <Card.Title>{plant.name}</Card.Title>
                                    <Card.Text>
                                        {plant.latin}
                                    </Card.Text>
                                    <Link to={"/pflanzen/" + plant.id}>
                                        <Button variant="primary">Details ansehen</Button>
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        );
    }
;

export default PlantOverview;
