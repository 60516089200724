const axios = require('axios');
const csv = require('csvtojson')

const driveUrl = 'https://docs.google.com/spreadsheets/d/10j2Yqa6RCUtacrlDsgV2HsrOwC340zughW-bydZ6_-U/export?format=csv';

const convertCsvToJson = async (csvData) => {
    const json = await csv({
        noheader: false
    })
        .fromString(csvData);

    for (const plant of json) {
        plant.id = parseInt(plant.id);
        if (plant.images) {
            plant.images = plant.images.replace(/ /g, ";");
            plant.images = plant.images.split(",");
        } else {
            plant.images = [];
        }
    }

    return json;
};

export async function getPlants() {
    const plants = (await axios.get(driveUrl)).data;
    return (await convertCsvToJson(plants));
}

export async function getPlantById(id) {
    const plants = await getPlants();
    return plants.filter(plant => plant.id === id)[0];
}

export function translateKey(key) {
    const translations = {
        name: 'Name',
        latin: 'Lateinisch',
        raiser: 'Züchter',
        waterAmount: 'Wasserbedarf',
        place: 'Standort',
        terra: 'Boden',
        care: 'Pflege',
        growHeight: 'Wuchshöhe',
        winter: 'Überwinterung',
        harvestTime: 'Erntezeit',
        cut: 'Schnitt',
        timeOfFruit: 'Fruchtreife',
        bought: 'Kaufzeitpunkt/-ort',
        descriptionOfDealer: 'Beschreibung des Verkäufers',
        ownNotices: 'Eigene Notizen'
    }
    return translations[key];
}

