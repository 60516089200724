import React, { useEffect, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import {
    useParams,
    Link
} from "react-router-dom";
import { getPlantById, translateKey } from "../plant-helper";

const PlantDetails = () => {
        let { id } = useParams();
        const [plant, setPlant] = useState();

        const fetchPlant = async () => {
            if (!plant) {
                const p = await getPlantById(parseInt(id));
                setPlant(p);
            }
        }

        useEffect(() => {
            fetchPlant();
        });

        return (
            <>
                {
                    !plant &&
                    <div className="text-center pt-5">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                }

                {
                    plant &&
                    <>
                        <header style={{
                            backgroundImage: `url(${plant.img})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                        }} className="align-text-bottom">
                            <Container>
                                <h1 className="pb-3" style={{
                                    paddingTop: '200px',
                                    textShadow: '1px 1px white'
                                }}>{plant.name}
                                    <small style={{ fontSize: '16px' }}
                                           className="text-muted d-inline-block ml-3">({plant.latin})</small>
                                </h1>
                            </Container>
                        </header>


                        <Container className="py-5">

                            <div className="text-right">
                                <Link to="/">
                                    <svg style={{ marginTop: '-3px' }} width="24" height="24"
                                         xmlns="http://www.w3.org/2000/svg" fillRule="evenodd"
                                         clipRule="evenodd">
                                        <path
                                            d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z"/>
                                    </svg>
                                    <span className="d-inline-block pl-2"
                                          style={{ color: 'black' }}>Zurück zur Übersicht</span>
                                </Link>
                            </div>


                            <h4 className="pt-5">{translateKey('waterAmount')}</h4>
                            <p>{plant.waterAmount ? plant.waterAmount : '-'}</p>

                            <h4 className="pt-5">{translateKey('place')}</h4>
                            <p>{plant.place ? plant.place : '-'}</p>

                            <h4 className="pt-5">{translateKey('terra')}</h4>
                            <p>{plant.terra ? plant.terra : '-'}</p>

                            <h4 className="pt-5">{translateKey('care')}</h4>
                            <p>{plant.care ? plant.care : '-'}</p>

                            <h4 className="pt-5">{translateKey('growHeight')}</h4>
                            <p>{plant.growHeight ? plant.growHeight : '-'}</p>

                            <h4 className="pt-5">{translateKey('winter')}</h4>
                            <p>{plant.winter ? plant.winter : '-'}</p>

                            <h4 className="pt-5">{translateKey('harvestTime')}</h4>
                            <p>{plant.harvestTime ? plant.harvestTime : '-'}</p>

                            <h4 className="pt-5">{translateKey('cut')}</h4>
                            <p>{plant.cut ? plant.cut : '-'}</p>

                            <h4 className="pt-5">{translateKey('timeOfFruit')}</h4>
                            <p>{plant.timeOfFruit ? plant.timeOfFruit : '-'}</p>

                            <h4 className="pt-5">{translateKey('cut')}</h4>
                            <p>{plant.cut ? plant.cut : '-'}</p>

                            <h4 className="pt-5">{translateKey('bought')}</h4>
                            <p>{plant.bought ? plant.bought : '-'}</p>

                            <h4 className="pt-5">{translateKey('descriptionOfDealer')}</h4>
                            <p>{plant.descriptionOfDealer ? plant.descriptionOfDealer : '-'}</p>

                            <h4 className="pt-5">{translateKey('ownNotices')}</h4>
                            <p>{plant.ownNotices ? plant.ownNotices : '-'}</p>

                            <h4 className="pt-5">{translateKey('raiser')}</h4>
                            <p>{plant.raiser ? plant.raiser : '-'}</p>

                            {plant.images && plant.images.length
                                ? plant.images.map((image, index) => (
                                    <img
                                        alt={plant.name + ' ' + index}
                                        src={image}
                                        key={index}
                                        className="img-fluid mt-5 d-block"
                                    />
                                ))
                                : <></>
                            }
                        </Container>
                    </>
                }
            </>);
    }
;


export default PlantDetails;
